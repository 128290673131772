var app = {

  init: function() {
    app.animation();
    app.accordion();
    app.modal();
    app.calculator();
    app.slider();
    app.menu();
    app.lang();
    app.fixedHeader();
    app.smoothscroll();
    app.lazyload();
  }, // init END

  animation: function() {
    new WOW().init();
  }, // animation END

  menu: function() {
    var mobile_menu = $('[data-modal="mobile-menu"]');
    var modal_overlay = $('.modal-overlay');

    $('#menu-trigger').on('click', function () {
      modal_overlay.css('display', 'block');
      mobile_menu.removeClass('hidden');
    });

    $('.modal-content .main-menu-link').on('click', function() {
      menu_close();
    });

    var menu_close = function() {
      modal_overlay.css('display', 'none');
      mobile_menu.addClass('hidden');
    }

  }, // menu END

  lang: function() {
    $('#lang-trigger').on('click', function () {
      $(this).toggleClass('active');
    });
  }, // menu END

  accordion: function() {
    $(".accordion-trigger").QuickAccord();
  }, // accordion END

  lazyload: function() {
    var bLazy = new Blazy({
        breakpoints: [{
          width: 576, // max-width
          src: 'data-src-small'
        },
        {
          width: 990, // max-width
          src: 'data-src-medium'
        }],
        success: function(element){
          setTimeout(function(){
          // We want to remove the loader gif now.
          // First we find the parent container
          // then we remove the "loading" class which holds the loader image
          var parent = element.parentNode;
          parent.className = parent.className.replace(/\bloading\b/,'');
        }, 200);
      }
    });
  }, // lazyload END

  slider: function() {

    var $slider = new Swiper('#gallery-slider', {
      lazy: true,
      slidesPerView: 3,
      speed: 400,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        480: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
    });

    var $calculatorSteps = new Swiper('#configuration-slider', {
      allowTouchMove: false,
      speed: 0,
      navigation: {
        nextEl: '.calc-next',
        prevEl: '.calc-prev',
      },
    });

  }, // slider END

  calculator: function() {
    var costValue = $('#cost-value'),
        tableImage = $('.table-image');

    // triggers
    var tableBase     = $('input[name="table-base"]'),
        tableTop      = $('input[name="tabletop-size"]'),
        tableOptions  = $('input[name="table-options"]'),
        tableColor    = $('input[name="table-color"]');

    // Form Send Inputs init
    // var output_TableBase     = $('input[id="table_base"]'),
    //     output_TableTop      = $('input[id="table_size"]'),
    //     output_TableOptions  = $('input[id="table_option"]'),
    //     output_TableColor    = $('input[id="table_color"]'),
    //     output_TableColor    = $('input[id="table_image"]');

    var obj_Images = {
      // ES 470
      0 : {
        'base'            : '1-1.png',
        'partition'       : '1-2.png',
        'cable-channel'   : '1-3.png',
        'complex'         : '1-4.png'
      },
      // EPI EE 470
      1 : {
        'base'            : '2-1.png',
        'partition'       : '2-2.png',
        'cable-channel'   : '2-3.png',
        'complex'         : '2-4.png'
      },
      // R600 (EPI EE 600)
      2 : {
        'base'            : '3-1.png',
        'partition'       : '3-2.png',
        'cable-channel'   : '3-3.png',
        'complex'         : '3-4.png'
      },
      // EPI Ei 650
      3 : {
        'base'            : '4-1.png',
        'partition'       : '4-2.png',
        'cable-channel'   : '4-3.png',
        'complex'         : '4-4.png'
      }
    }

    tableBase.on('click', function() {
      result();
    });

    tableTop.on('click', function() {
      result();
    });

    tableOptions.on('click', function() {
      result();
    });

    tableColor.on('click', function() {
      result();
    });

    function result(tableBaseCost, tabletopCost) {

      var tableBaseName          = $('input[name="table-base"]:checked').attr('data-base-name'),
          tableBaseCost         = parseInt($('input[name="table-base"]:checked').val()),
          tableTopName          = $('input[name="tabletop-size"]:checked').attr('data-partition-name'),
          tableTopCost          = parseInt($('input[name="tabletop-size"]:checked').val()),
          tableOptionName       = $('input[name="table-options"]:checked').attr('data-option-name'),
          tableOptionValue      = $('input[name="table-options"]:checked').val(),
          tableColorName       = $('input[name="table-color"]:checked').attr('data-color-name'),
          plug                  = parseInt($('input#table-plug').val()),
          tableDecsriptionIndex = $('input[data-index]:checked').data('index'),
          imgPath               = 'images/tables/',       // dev
          // imgPath               = '/wp-content/themes/ergo/images/tables/',  // prod
          // tableImageSrc         = $('input[name="table-options"]:checked').data('image'),
          tableImageSrc         = imgPath + obj_Images[tableDecsriptionIndex][tableOptionValue];

      console.log(typeof tableImageSrc);
      
      $( ".description-section" ).each(function( index ) {

        if ( tableDecsriptionIndex != index ) {
          $( this ).addClass('hidden');
        } else {
          $( this ).removeClass('hidden');
        }

      });

      console.log('--- ' + tableOptionValue + ' ---');
      
      // tableImage.attr("src", tableImageSrc);
      tableImage.css('background-image', 'url(' + tableImageSrc + ')');

      switch (tableOptionValue) {
        // case "base":
        //   partition = 0;
        //   break;
        case "partition":
          var cableChannel  = 0,
              partition     = parseInt($('input[name="tabletop-size"]:checked').data('partition-cost'));
          // console.log('***' + partition + '***');
          break;
        case "cable-channel":
          var cableChannel  = parseInt($('input#table-channel').val()),
              partition     = 0;
          break;
        case "complex":
          var cableChannel  = parseInt($('input#table-channel').val()),
              partition     = parseInt($('input[name="tabletop-size"]:checked').data('partition-cost'));
          break;
        default:
          var cableChannel  = 0,
              partition     = 0;
              // tableImageSrc = $('input[name="table-options"]:checked').data('image');
      }

      // база + столешница + заглушка + кабелеканал + перегородка
      resultCost = tableBaseCost + tableTopCost + plug + cableChannel + partition;

      console.log('стоимость БАЗЫ СТОЛА: '   + tableBaseCost);
      console.log('стоимость СТОЛЕШНИЦЫ: '   + tableTopCost);
      console.log('стоимость ЗАГЛУШКИ: '     + plug);
      console.log('стоимость КАБЕЛЬКАНАЛА: ' + cableChannel);
      console.log('стоимость ПЕРЕГОРОДКИ: '  + partition);
      console.log('картинка: '               + tableImageSrc);
      // console.log('описание: '               + tableDecsriptionIndex);

      console.log('ИТОГО: ' + resultCost);
      console.log('-------------------');


      costValue.text(resultCost.toFixed(0));

      $('input[id="table_base"]').val(tableBaseName);
      $('input[id="table_size"]').val(tableTopName);
      $('input[id="table_option"]').val(tableOptionName);
      $('input[id="table_color"]').val(tableColorName);
      $('input[id="table_image"]').val('http://' + document.domain + tableImageSrc);

      console.log(' --------------------------------------------- ');
      var table_base = $('input[id="table_base"]').val();
      var table_size = $('input[id="table_size"]').val();
      var table_option = $('input[id="table_option"]').val();
      var table_color = $('input[id="table_color"]').val();
      var table_image = $('input[id="table_image"]').val();
      console.log('send tabletopName: ' + table_base );
      console.log('send tabletopName: ' + table_size );
      console.log('send tabletopName: ' + table_option );
      console.log('send tabletopName: ' + table_color );
      console.log('send tabletopName: ' + table_image );
    }

    result();

    // costValue.text($('input[name="table-base"]:checked').val());

  }, // calculator END

  modal: function() {
    var mobile_menu = $('[data-modal="mobile-menu"]');
    var modal_overlay = $('.modal-overlay');
    var modal_window = $('.modal-window');

    // request modal
    var request_modal = $('[data-modal="request-form"]');

    $('#request-button').click(function(event) {
      event.preventDefault();
      modal_overlay.css('display', 'block');
      request_modal.removeClass('hidden');
    });

    // calc modal
    var calc_modal = $('[data-modal="calc-form"]');

    $('.calc-button').click(function(event) {
      event.preventDefault();
      modal_overlay.css('display', 'block');
      calc_modal.removeClass('hidden');
    });

    // MODALS
    modal_overlay.click(function(event) {
      e = event || window.event
      if (e.target == this) {
        $(modal_overlay).css('display', 'none');
        mobile_menu.addClass('hidden');
        request_modal.addClass('hidden');
      }
    });

    $('.modal-close').click(function() {
      modal_overlay.css('display', 'none');
      // mobile_menu.addClass('hidden');
      // request_modal.addClass('hidden');
      // calc_modal.addClass('hidden');
      modal_window.addClass('hidden');
    });


    // animateCSS('.widget-logo', 'zoomIn');

    // function animateCSS(element, animationName, callback) {

    //     const node = document.querySelector(element)
    //     node.classList.add('animated', animationName)

    //     function handleAnimationEnd() {
    //         node.classList.remove('animated', animationName)
    //         node.removeEventListener('animationend', handleAnimationEnd)

    //         if (typeof callback === 'function') callback()
    //     }

    //     node.addEventListener('animationend', handleAnimationEnd)
    // }

    // animateCSS('.button', 'fadeInDown');

    // // or

    // animateCSS('.button', 'fadeInDown', function() {
    //   // Do something after animation
    // })
  }, // modal END

  fixedHeader: function() {
    var header = $('.main-header');

    $(window).scroll(function(){

      if ( $(window).scrollTop() >= 1) {
        header.addClass('fixed-header');
      } else {
        header.removeClass('fixed-header');
      }

    });

  }, // fixedHeader END

  smoothscroll: function() {
    $(function() {
      /**
      * Smooth scrolling to page anchor on click
      **/
      $("a.anchor-link[href*='#']:not([href='#'])").click(function(e) {
        e.preventDefault();
        if ( location.hostname == this.hostname && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"") ) {
          var anchor = $(this.hash);
          anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
          if ( anchor.length ) { $("html, body").animate( { scrollTop: anchor.offset().top + 0 }, 800); }
        }
      });
    });
  } // smoothscroll END
}

jQuery(document).ready(function($) {
  console.log( 'Документ и все ресурсы загружены' );
  app.init();

  // function menuAnimation() {
  //   $('.main-menu-link').addClass('animated fadeInDown');
  //   $('.widget-logo, .widget-lang').addClass('animated fadeIn');
  // }

// menuAnimation();

// setTimeout(menuAnimation, 1000);


// ===================================================================================

  function intro_animation() {

  }

// // Получаем нужный элемент
// var about_us_title = document.querySelector('#about-us .section-title');
// var article_title = document.querySelector('#article .section-title');

// var Visible = function (target) {
//   // Все позиции элемента
//   var targetPosition = {
//       top: window.pageYOffset + target.getBoundingClientRect().top,
//       left: window.pageXOffset + target.getBoundingClientRect().left,
//       right: window.pageXOffset + target.getBoundingClientRect().right,
//       bottom: window.pageYOffset + target.getBoundingClientRect().bottom
//     },
//     // Получаем позиции окна
//     windowPosition = {
//       top: window.pageYOffset,
//       left: window.pageXOffset,
//       right: window.pageXOffset + document.documentElement.clientWidth,
//       bottom: window.pageYOffset + document.documentElement.clientHeight
//     };

//   if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
//     targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
//     targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
//     targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
//     // Если элемент полностью видно, то запускаем следующий код
//     console.clear();
//     console.log('Вы видите элемент :)');

//     $(target).addClass('animated fadeInDown');

//   } else {
//     // Если элемент не видно, то запускаем этот код
//     console.clear();
//   };
// };

// // Запускаем функцию при прокрутке страницы
// window.addEventListener('scroll', function() {
//   Visible (about_us_title);
//   Visible (article_title);
// });

// // А также запустим функцию сразу. А то вдруг, элемент изначально видно
// Visible (about_us_title);
// Visible (article_title);


// ===================================================================================

// $('#input').change(function() {
//   console.log($( this ).val());
// });

// $('#input').keyup(function() {
  
//   var availableTags = [
//     "ActionScript",
//     "AppleScript",
//     "BASIC"
//   ];
  
//   if(availableTags.indexOf($(this).val()) == -1 ) {
  
//     // $('input[type="submit"]').prop('disabled', true);
  
//   } else {
  
//     $( this ).val('');
  
//     // $('input[type="submit"]').prop('disabled', false);
  
//   }
  
//   console.log($( this ).val());
// });

    // animateCSS('.widget-logo', 'zoomIn');

    // function animateCSS(element, animationName, callback) {

    //     const node = document.querySelector(element)
    //     node.classList.add('animated', animationName)

    //     function handleAnimationEnd() {
    //         node.classList.remove('animated', animationName)
    //         node.removeEventListener('animationend', handleAnimationEnd)

    //         if (typeof callback === 'function') callback()
    //     }

    //     node.addEventListener('animationend', handleAnimationEnd)
    // }

    // animateCSS('.button', 'fadeInDown');

    // // or

    // animateCSS('.button', 'fadeInDown', function() {
    //   // Do something after animation
    // })


  // Preloader action start
  function preloader_hide() {
    $('#preloader .logo-image').addClass('logo-fade-out');
    $('#preloader').addClass('hidden');
  }

  function preloader_remove() {
    $('#preloader').remove();
  }

  setTimeout(preloader_hide, 1000);
  setTimeout(preloader_remove, 2000);

});
